import { MpCodeTypes } from 'src/models/matchingProfiles/types';
import { safeCallSegment } from '../helpers';
import { TendersSearchSchemaType } from 'src/models/procurements/TendersSearch/schemas';
import { TendersSearchEventSource } from 'src/models/procurements/TendersSearch/types';

export function trackRejectProcurements(
  procurementIds: readonly string[],
  eventSource: string,
  type: string | undefined,
  relevanceScores?: (number | undefined)[]
): void {
  safeCallSegment(() => {
    analytics.track('Reject procurements', {
      procurementIds,
      eventSource,
      type,
      relevanceScores
    });
  });
}

export function trackUnrejectProcurements(
  procurementIds: readonly string[],
  eventSource: string,
  type: string | undefined
): void {
  safeCallSegment(() => {
    analytics.track('Unreject procurements', {
      procurementIds,
      eventSource,
      type
    });
  });
}

export function trackPrintSummary(procurement: { id: string; name: string }): void {
  safeCallSegment(() => {
    analytics.track('Print summary', {
      procurement
    });
  });
}

export function trackStarProcurement(procurement: { id: string; name?: string }, eventSource: string): void {
  safeCallSegment(() => {
    analytics.track('Star procurement', {
      procurement,
      eventSource
    });
  });
}

export function trackUnstarProcurement(procurement: { id: string; name?: string }, eventSource: string): void {
  safeCallSegment(() => {
    analytics.track('Unstar procurement', {
      procurement,
      eventSource
    });
  });
}

export function trackOpenProcurementFromEmail(procurement: { id: string; name?: string }): void {
  safeCallSegment(() => {
    analytics.track('Open procurement from email', {
      procurement
    });
  });
}

export function trackViewDocumentPreview(eventSource: string, procurementIds?: string[]): void {
  safeCallSegment(() => {
    analytics.track('View Document Preview', {
      eventSource,
      procurementIds
    });
  });
}

export function trackTendersFilterSearch(eventSource: TendersSearchEventSource): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Search Field`);
  });
}

export function trackTendersFilterBuyers(eventSource: TendersSearchEventSource): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering Buyers`);
  });
}

export function trackTendersFilterCodes(
  eventSource: TendersSearchEventSource,
  type: MpCodeTypes,
  codes?: string[]
): void {
  const optionId = codes?.find(item => !codes?.includes(item)) ?? codes?.[0];
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering ${type}`, { optionId });
  });
}

export function trackTendersFilterAssigned(eventSource: TendersSearchEventSource): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering Assigned`);
  });
}

export function trackTendersFilterDate(
  eventSource: TendersSearchEventSource,
  type: keyof Pick<TendersSearchSchemaType, 'published' | 'deadline'>
): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering ${type}`);
  });
}

export function trackTendersFilterStatuses(eventSource: TendersSearchEventSource, status: string): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Status`, {
      status: {
        [status.toLowerCase()]: true
      }
    });
  });
}

export function trackTendersFilterContractEndTimeSpan(
  eventSource: TendersSearchEventSource,
  data: { start?: number; end?: number }
): void {
  safeCallSegment(() => {
    analytics.track(`${eventSource} Filtering Contract End: Time Span`, data);
  });
}

import React, { useCallback, JSX, ReactElement } from 'react';
import { MpKeywordAndGroup, MpKeywordSettings } from 'src/models/matchingProfiles/types';
import PlainDropDown from 'src/common/PlainDropDown';
import { IPlainDropdownValue } from 'src/common/PlainDropDown/types';
import { useKeywords } from 'src/models/keywords/hooks';
import {
  trackMPEditKeywordColor,
  trackMPEditKeywordSearchLogic,
  trackMPEditKeywordWhereToSearch
} from 'src/segment/events';

interface IProps<T extends string> {
  label: string | ReactElement;
  selectedValue?: T;
  values: IPlainDropdownValue<T>[];
  disabled?: boolean;
  keywords: MpKeywordAndGroup[];
  field: keyof MpKeywordSettings;
  className?: string;
  hideLabel?: boolean;
  dropdownIcon?: boolean;
  mode: 'single' | 'bulk';
}

export function SelectField<T extends string>(props: IProps<T>): JSX.Element {
  const { values, selectedValue, label, hideLabel, dropdownIcon, keywords, field, disabled, className, mode } = props;
  const { data, updateKeywords } = useKeywords();

  const onUpdate = useCallback(
    (val: T) => {
      const singleKeywords: string[] = [];
      const keywordGroups: { keywordIds: string[]; groupId: string | null }[] = [];

      keywords.forEach(keyword => {
        if (keyword.groupId) {
          keywordGroups.push({ keywordIds: keyword.values.map(item => item.id), groupId: keyword.groupId });
        } else {
          singleKeywords.push(keyword.values[0].id);
        }
      });

      updateKeywords(singleKeywords, keywordGroups, { [field]: val });

      if (field === 'highlightColor') {
        trackMPEditKeywordColor(data.eventNamePrefix, data.filterLogic, val, mode, data.mp);
      } else if (field === 'searchType') {
        trackMPEditKeywordWhereToSearch(data.eventNamePrefix, data.filterLogic, val, mode, data.mp);
      } else if (field === 'searchLogic') {
        trackMPEditKeywordSearchLogic(data.eventNamePrefix, data.filterLogic, val, mode, data.mp);
      }
    },
    [data.eventNamePrefix, data.filterLogic, data.mp, field, keywords, mode, updateKeywords]
  );

  return (
    <PlainDropDown
      values={values}
      selectedValue={selectedValue}
      label={label}
      onSelect={onUpdate}
      hideLabel={hideLabel}
      dropdownIcon={dropdownIcon}
      disabled={disabled}
      className={className}
      type="single"
    />
  );
}

export default SelectField;

import { IBidIdentifier } from 'src/models/bids/Bids/types';
import { DndType } from 'src/types/dnd';
import { BidItemType } from '@tendium/prom-types/tender';
import { PageView } from 'src/models/users/Preferences/types';
import { ActivePreviewTab } from '../types';
import {
  AwardStatus,
  BuyerType,
  JunctionLogic,
  KeywordWidth,
  Language,
  SearchOnlyStatus,
  SortOrder as TendersSortOrder
} from '@tendium/prom-types';
import isObject from 'src/helpers/isObject';
import isString from 'src/helpers/isString';
import { TendersTableItem } from '../TendersViews';
import { IApiTenderHighlight } from '../Preview/types';
import { IApiTender, IApiTenderBox, ITenderGeneral, ITenderTimeline, OCDSTenderObject } from '../Tender/types';
import { ContractValueRange, MpNewKeyword, MpNewKeywordGroup } from 'src/models/matchingProfiles/types';
import { ContractEndFilterInput } from '@tendium/prom-types/schema';
import { OcdsPartyRole } from '../TenderFree/types';
import { IApiOrganization } from '../../organisations';

export const DEFAULT_TENDERS_VIEW = PageView.table;
export const DEFAULT_TENDERS_TAB = ActivePreviewTab.Info;
export const DEFAULT_INBOX_TAB = ActivePreviewTab.Email;

export interface ITendersDndItem {
  type: DndType.TendersItem;
  dndItem: TendersTableItem;
  originIds?: string[];
  originType?: BidItemType;
  bidIds?: IBidIdentifier[];
}

export type TendersSortVars = { key: TendersSortName; sort: TendersSortOrder };
type DateFilter = {
  start: number;
  end: number;
};
export type SupplierOrg = {
  orgId: string;
  status: AwardStatus;
};
export type SupplierString = {
  orgName: string;
  status: AwardStatus;
};
export type TenderOrgFilter = {
  orgId: string;
  orgName?: string;
  role: OcdsPartyRole;
};
type TendersKeywordGroup = MpNewKeywordGroup & { groupId?: string };
export type ApiTendersSearchInput = {
  query?: Omit<ApiTendersVars, 'advanced'>;
  advanced?: ApiTendersAdvancedVars;
};
export type ApiTendersVars = {
  amount?: number;
  isStarred?: boolean;
  isUnhandled?: boolean;
  matchingProfileId?: string;
  offset?: number;
  sortOnField?: TendersSortVars;
  advanced?: ApiTendersAdvancedVars;
};
export type ApiTendersAdvancedVars = {
  assignedTo?: string[];
  awarded?: boolean;
  buyerOrgId?: string;
  buyerOrgIds?: string[];
  buyerSearchStrings?: string[];
  contractEnd?: ContractEndFilterInput;
  contractValueRange?: ContractValueRange;
  cpvCodes?: string[];
  cpvCodesExact?: string[];
  documentSearch?: boolean;
  highlight?: boolean;
  keywordWidth?: KeywordWidth;
  filterKeywordLanguages?: Language[];
  highlightKeywordLanguages?: Language[];
  keywordGroups?: TendersKeywordGroup[];
  keywordArgs?: MpNewKeyword[];
  name?: string;
  nutsCodes?: string[];
  nutsCodesExact?: string[];
  onlyStatus?: SearchOnlyStatus;
  procuringAgency?: string;
  procuringAgencyTypes?: BuyerType[];
  publicationDate?: DateFilter;
  search?: string[];
  submissionDeadline?: DateFilter;
  supplierOrgIds?: SupplierOrg[];
  supplierSearchLogic?: JunctionLogic;
  supplierSearchStrings?: SupplierString[];
  contractValueExcludeMissingValue?: boolean;
  profileId?: string;
  partyFilter?: TenderOrgFilter;
};

export type TendersItemCustomData = {
  isCommented: boolean;
  highlights?: IApiTenderHighlight[];
  nextId?: string;
  bid?: IBidIdentifier;
  matchedCpvs?: string[];
};

export interface ApiTendersTimeline<T> extends Pick<ITenderTimeline<T>, 'availableDate' | 'deadline'> {}
export interface ApiTendersGeneral<T>
  extends Pick<ITenderGeneral<T>, 'name' | 'buyerBoxes' | 'contractDurationBoxes' | 'contractValueBoxes'> {}
export type ApiTendersItem = Omit<
  IApiTender,
  'general' | 'timeline' | 'custom' | 'boxes' | 'fileCategories' | 'comments'
> & {
  timeline: ApiTendersTimeline<IApiTenderBox>;
  general: ApiTendersGeneral<IApiTenderBox>;
  rejected: boolean | null;
  score?: number | null;
};

export type TendersItem<TData extends ApiTendersItem> = TData & TendersItemCustomData;

export type ApiTendersResponse<TData> = {
  count: number;
  procurementsWithScore: {
    procurement: TData;
    highlights: IApiTenderHighlight[];
    matchedCpvs: string[];
    score: number | null;
  }[];
  fetchedTendersCount: number;
};

export type ApiTendersDataFull = {
  getTenders: ApiTendersResponse<ApiTendersItem>;
};
export type ApiTendersData = {
  getSimpleTenders: ApiTendersResponse<ApiTendersItem>;
};

export enum TendersFilterStatus {
  recent = 'Active',
  expired = 'Expired',
  awarded = 'Awarded',
  all = 'All'
}

export const FILTER_TENDERS_STATUS = [
  TendersFilterStatus.recent,
  TendersFilterStatus.expired,
  TendersFilterStatus.awarded,
  TendersFilterStatus.all
];

export enum TendersSortName {
  published = 'Available',
  title = 'Name',
  buyer = 'Buyer',
  deadline = 'Deadline',
  expiration = 'Expiration',
  relevanceScore = 'RelevanceScore'
}

export { TendersSortOrder };

export const SORTABLE_TENDERS_COLUMNS = [
  TendersSortName.published,
  TendersSortName.title,
  TendersSortName.buyer,
  TendersSortName.deadline,
  TendersSortName.relevanceScore
];

export interface UpdateTenderSearchVarsOptions {
  tenderStatus?: TendersFilterStatus;
}

export function isTendersSortName(u: unknown): u is TendersSortName {
  return isString(u) && Object.values(TendersSortName).some(c => c === u);
}
export function isTendersDndItem(u: unknown): u is ITendersDndItem {
  return isObject(u) && 'type' in u && u.type === DndType.TendersItem;
}

export type SupplierContractObject = {
  supplier?: IApiOrganization;
  tenders: IdentifiableTenderObject[];
  contractCount?: number;
};

export type IdentifiableTenderObject = {
  id: string;
  tender: OCDSTenderObject;
};

export const SUPPLIERS_PAGE_SIZE = 5;

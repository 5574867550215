import React, { FC } from 'react';
import { Button, Modal, CopyBox } from 'src/common';
import { useTranslation } from 'react-i18next';
import styles from '../index.module.scss';

interface IProps {
  onClose: () => void;
  keywords: string[];
}

export const KeywordsLimitWarnModal: FC<IProps> = props => {
  const { onClose, keywords } = props;
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Tenders.monitoringProfileKeywordLimitTitle')}
      visible
      onCancel={onClose}
      footer={
        <Button type={'primary'} onClick={onClose}>
          {t('Common.done')}
        </Button>
      }
    >
      <p>{t('Tenders.monitoringProfileKeywordLimit')}</p>
      <p>{t('Tenders.monitoringProfileKeywordLimit2')}</p>
      <CopyBox value={keywords.join(', ')} className={styles.copyBox} />
    </Modal>
  );
};

export default KeywordsLimitWarnModal;

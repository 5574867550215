import { TendersSortName, TendersSortOrder } from '../../Tenders/types';
import { ContractEndRangeMode, ContractEndSearchType, JunctionLogic } from '@tendium/prom-types';
import { KEYWORD_WIDTH, TENDERS_SEARCH_INT_VARS } from '../types';
import {
  buyerFilterKeys,
  contractEndFilterKeys,
  contractValueFilterKeys,
  cpvCodeFilterKeys,
  keywordFilterKeys,
  nutsCodeFilterKeys,
  searchFilterKeys,
  supplierFilterKeys,
  TendersSearchSchemaType
} from '../schemas';

export const EC_ALLOWED_URL_PARAMS = [
  ...searchFilterKeys,
  ...contractEndFilterKeys,
  ...nutsCodeFilterKeys,
  ...cpvCodeFilterKeys,
  ...contractValueFilterKeys,
  ...keywordFilterKeys,
  ...buyerFilterKeys,
  ...supplierFilterKeys
];

export const EC_INIT_VARS: TendersSearchSchemaType = {
  amount: TENDERS_SEARCH_INT_VARS.amount,
  offset: TENDERS_SEARCH_INT_VARS.offset,
  sort: {
    key: TendersSortName.expiration,
    sort: TendersSortOrder.ASCENDING
  },
  contractEnd: {
    searchMode: ContractEndSearchType.LastPossibleEndDate,
    rangeMode: ContractEndRangeMode.RELATIVE
  },
  contractValueExcludeMissingValue: false,
  supplierSearchLogic: JunctionLogic.OR,
  keywordWidth: KEYWORD_WIDTH
};

export const EC_LIGHT_CONTRACT_END = {
  ...EC_INIT_VARS.contractEnd,
  relativeStart: 6,
  relativeEnd: 18
};

export interface ECFilterDiffs {
  keywordFilter: boolean;
  supplierFilter: boolean;
  buyerFilter: boolean;
  contractValueFilter: boolean;
  cpvFilter: boolean;
  nutsFilter: boolean;
  contractEndFilter: boolean;
}

import { safeCallSegment } from '../helpers';

export function trackECSaveNew(ecName: string): void {
  safeCallSegment(() => {
    analytics.track('EC save new', {
      ecName
    });
  });
}

export function trackECSaveSearch(ecName: string): void {
  safeCallSegment(() => {
    analytics.track('EC save search', {
      ecName
    });
  });
}

export function trackECClearActiveFilters(mpId: string): void {
  safeCallSegment(() => {
    analytics.track('EC clear', {
      mpId
    });
  });
}

export function trackECDeleteSavedSearch(ecId: string): void {
  safeCallSegment(() => {
    analytics.track('EC delete search', {
      ecId
    });
  });
}

export function trackECContractValue(min?: number, max?: number, currency?: string, checkbox?: boolean): void {
  safeCallSegment(() => {
    analytics.track('EC Contract value', {
      min,
      max,
      currency,
      checkbox: checkbox ? 'checked' : 'unchecked'
    });
  });
}

export function trackECSupplier(supplierId: string): void {
  safeCallSegment(() => {
    analytics.track('EC Supplier', {
      supplierId
    });
  });
}

export function trackECDateRange(): void {
  safeCallSegment(() => {
    analytics.track('EC Date range');
  });
}

import { Paths } from 'src/pages/paths';
import { router } from 'src';
import {
  IApiBoxField,
  IApiBoxFieldDate,
  IApiTenderBox,
  IBoxField,
  IBoxFieldArray,
  IBoxFieldBoolean,
  IBoxFieldCurrency,
  IBoxFieldDate,
  IBoxFieldDateRange,
  IBoxFieldEmail,
  IBoxFieldNumber,
  IBoxFieldRange,
  IBoxFieldString,
  IBoxFieldUrl,
  isFieldDate,
  isFieldString,
  ITenderBox
} from './Tender/types';
import { FileExt } from '../file/types';
import { BoxFieldCurrencyRange, BoxFieldString, BoxFieldUrl, TenderBoxFactory } from '.';
import { OcdsParty, OcdsPartyRole } from './TenderFree/types';
import { ContractEndRangeMode, SchemaUtils } from '@tendium/prom-types';
import { isNotUndefined } from '../../helpers';
import { IApiOrganization } from '../organisations';
import { BidderBoxFieldName } from './PreviewOutcome/types';
import dayjs from 'dayjs';
import { ApiTendersAdvancedVars } from './Tenders/types';
const { BuyerBoxFieldNames, sortBuyerBoxes } = SchemaUtils.TenderBox;

/** @fixme `isTenderPage` broken coz `Paths.TENDER_PAGE === ':id'` */
export function isTenderPage(): boolean {
  return Paths.TENDER_PAGE.split('/')[1] === router.state.location.pathname.split('/')[1];
}

const PREVIEW_FILE_EXT = [FileExt.pdf, FileExt.docx];
const CONVERT_FILE_EXT = [FileExt.docx];
/** @deprecated use instead DocFile class */
export function isPreviewableFile(fileExt: string): boolean {
  return fileExt in FileExt && PREVIEW_FILE_EXT.includes(fileExt as FileExt);
}
/** @deprecated use instead DocFile class */
export function isConvertableFile(fileExt: string): boolean {
  return fileExt in FileExt && CONVERT_FILE_EXT.includes(fileExt as FileExt);
}
/** @deprecated use instead DocFile class */
export function getFileNameFromPath(filePath: string): string {
  return filePath.substring(filePath.lastIndexOf('/') + 1, filePath.length);
}

// TODO: move to Field model in PROM-3142
export function toCorrectEmail(email: string): string {
  if (!email) return '';
  const MAIL_TO = 'mailto:';
  return email.startsWith(MAIL_TO) ? email : `${MAIL_TO}${email}`;
}

export function fieldFilter(field?: IApiBoxField | IBoxField): boolean {
  return (
    !!(field as IBoxFieldArray).array ||
    ((field as IBoxFieldBoolean).boolean !== undefined && (field as IBoxFieldBoolean).boolean !== null) ||
    !!(field as IBoxFieldNumber).number ||
    !!(field as IBoxFieldCurrency).amount ||
    !!(field as IBoxFieldCurrency).currency ||
    !!(field as BoxFieldCurrencyRange).from ||
    !!(field as BoxFieldCurrencyRange).to ||
    !!(field as IBoxFieldDate).date ||
    !!(field as IBoxFieldDateRange).from ||
    !!(field as IBoxFieldDateRange).to ||
    !!(field as IBoxFieldRange).from ||
    !!(field as IBoxFieldRange).to ||
    !!(field as IBoxFieldString).string ||
    !!(field as IBoxFieldUrl).url ||
    !!(field as IBoxFieldEmail).email
  );
}

export function toBuyerName(buyerBox?: ITenderBox): string | undefined {
  if (!buyerBox) return undefined;
  const name = buyerBox?.fields.find(field => field.name === BuyerBoxFieldNames.NAME);
  return isFieldString(name) ? name.string ?? undefined : undefined;
}

export function toBuyerOrgId(buyerBox?: ITenderBox): string | undefined {
  if (!buyerBox) return undefined;
  const orgId = buyerBox?.fields.find(field => field.name === BuyerBoxFieldNames.ORG_ID);
  return isFieldString(orgId) ? orgId.string ?? undefined : undefined;
}

export function ocdsPartyToBuyerLinkBox(ocdsParty?: OcdsParty): ITenderBox {
  return new TenderBoxFactory().create('linkToProcurerBoxes', [
    new BoxFieldUrl('', ocdsParty?.details?.url ?? undefined)
  ]);
}

export function ocdsPartyToBuyerBox(ocdsParty?: OcdsParty): ITenderBox {
  return new TenderBoxFactory().create('buyerBoxes', [
    new BoxFieldString(BuyerBoxFieldNames.NAME, ocdsParty?.name),
    new BoxFieldString(BuyerBoxFieldNames.ORG_ID, ocdsParty?.identifier?.id)
  ]);
}

export function ocdsFilterOnlyBuyerBoxes(ocdsParties?: OcdsParty[]): OcdsParty[] {
  return (
    ocdsParties?.filter(
      party => party.roles?.includes(OcdsPartyRole.Buyer) || party.roles?.includes(OcdsPartyRole.LeadBuyer)
    ) ?? []
  );
}

export function getSortedBuyerBoxes(boxes?: IApiTenderBox[]): IApiTenderBox[] {
  return sortBuyerBoxes<IApiTenderBox>(boxes ?? []);
}

export function ocdsFilterOnlySupplierBoxes(ocdsParties?: OcdsParty[]): OcdsParty[] {
  return ocdsParties?.filter(party => party.roles?.includes(OcdsPartyRole.Supplier)) ?? [];
}

export function toSupplierOrgId(supplierBox?: ITenderBox): string | undefined {
  if (!supplierBox) return undefined;
  const orgId = supplierBox?.fields.find(field => field.name === BidderBoxFieldName.NationalRegistrationNumber);
  return isFieldString(orgId) ? orgId.string ?? undefined : undefined;
}

export function toSupplierOrgName(supplierBox?: ITenderBox): string | undefined {
  if (!supplierBox) return undefined;
  const orgName = supplierBox?.fields.find(field => field.name === BidderBoxFieldName.BidderName);
  return isFieldString(orgName) ? orgName.string ?? undefined : undefined;
}

export function toAwardedSuppliers(boxes?: ITenderBox[]): IApiOrganization[] {
  if (!boxes) return [];
  return [...boxes]
    .filter(box => box.specificationId === 'CONTRACT_AWARD_INFORMATION_2')
    .filter(filterAwardedBidderStatus)
    .map(box => {
      const organisationNumber = toSupplierOrgId(box);
      if (!organisationNumber) return undefined;
      return {
        organisationName: toSupplierOrgName(box),
        organisationNumber
      } as IApiOrganization;
    })
    .filter(isNotUndefined);
}

export function filterAwardedBidderStatus(supplierBox?: ITenderBox): boolean {
  if (!supplierBox) return false;
  const BidderStatus = supplierBox?.fields.find(field => field.name === BidderBoxFieldName.BidderStatus);
  return isFieldString(BidderStatus) && BidderStatus.string === 'Awarded contract';
}

export function getBuyerOrgIdFromFields(fields: IApiBoxField[]): string | undefined {
  if (!fields) return undefined;
  const orgId = fields.find(field => field.name === BuyerBoxFieldNames.ORG_ID);
  return isFieldString(orgId) ? orgId.string ?? undefined : undefined;
}

export function getDateFromDateFields(fields: IApiBoxFieldDate[]): number | undefined {
  return fields.map(field => (isFieldDate(field) ? field.date : 0))[0] ?? undefined;
}

export const getRelativeEpochTime = (months: number): number | undefined =>
  new Date(new Date().setMonth(new Date().getMonth() + months)).getTime();

export const getStartDate = (advanced: ApiTendersAdvancedVars): number | undefined => {
  return advanced?.contractEnd?.rangeMode === ContractEndRangeMode.ABSOLUTE
    ? dayjs(advanced?.contractEnd?.start).valueOf()
    : advanced?.contractEnd?.relativeStart
    ? getRelativeEpochTime(dayjs(advanced?.contractEnd?.relativeStart).valueOf())
    : undefined;
};

export const getEndDate = (advanced: ApiTendersAdvancedVars): number | undefined => {
  return advanced?.contractEnd?.rangeMode === ContractEndRangeMode.ABSOLUTE
    ? dayjs(advanced?.contractEnd?.end).valueOf()
    : advanced?.contractEnd?.relativeEnd
    ? getRelativeEpochTime(dayjs(advanced?.contractEnd?.relativeEnd).valueOf())
    : undefined;
};

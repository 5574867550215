import React, { FC, useCallback, useMemo, useState } from 'react';
import styles from './index.module.scss';
import KeywordsPreview from './KeywordsPreview';
import KeywordsInput from './KeywordsInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { MpKeywordAndGroup } from 'src/models/matchingProfiles/types';
import { Form, Button, InfoIcon } from 'src/common';
import KeywordsLimitWarnModal from './KeywordsLimitWarnModal';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { KEYWORD_LIMIT } from '@tendium/prom-types';
import { useFeatureFlag, FeatureFlag } from 'src/helpers';
import { useKeywords } from 'src/models/keywords/hooks';
import { trackMPEditAddKeywords, trackMPEditKeywordShortcut } from 'src/segment/events';

const ADD_KEYWORDS_BUTTON_ARIA_LABEL = 'add-keywords-button';

interface IProps {
  placeholder: string;
  disabled: boolean;
  addButtonLabel?: string;
  keywords?: MpKeywordAndGroup[];
  newKeywords?: MpKeywordAndGroup[];
  withPreview?: boolean;
  extendedVersion?: boolean;
  onShowMore?: () => void;
}

export const KeywordsArea: FC<IProps> = props => {
  const { disabled, placeholder, withPreview, extendedVersion, keywords, addButtonLabel, newKeywords, onShowMore } =
    props;
  const { t } = useTranslation();
  const isAndKeywordsFeature = useFeatureFlag(FeatureFlag.MatchingProfile_AndKeywords);
  const { data, createKeywords } = useKeywords();

  const [form] = Form.useForm();
  const [restKeywords, setRestKeywords] = useState<string[]>([]);
  const [showWarning, setShowWarning] = useState(false);
  const onHideWarning = useCallback(() => {
    setShowWarning(false);
    setRestKeywords([]);
  }, []);

  const getKeywords = useCallback(
    (input: string) => {
      const newAddedKeywords: string[] = input.trim().split(',').filter(Boolean);
      const singleKeywords: string[] = [];
      const keywordGroups: string[][] = [];

      newAddedKeywords.forEach(splitKeywords => {
        if (isAndKeywordsFeature && splitKeywords.includes('+')) {
          const group = splitKeywords
            .split('+')
            .map(_ => _.trim())
            .filter(Boolean);

          if (group.length > 1) {
            keywordGroups.push(group);
          } else {
            singleKeywords.push(group[0]);
          }
        } else {
          singleKeywords.push(splitKeywords.trim());
        }
      });

      return {
        newAddedKeywords,
        singleKeywords,
        keywordGroups
      };
    },
    [isAndKeywordsFeature]
  );

  const onCreateKeywords = useCallback(
    (input: string): void => {
      if (!input) return;

      const { newAddedKeywords, singleKeywords, keywordGroups } = getKeywords(input);
      const totalKeywords =
        [...(keywords ?? []), ...(newKeywords ?? [])].reduce((acc, group) => acc + group.values.length, 0) +
        singleKeywords.length +
        keywordGroups.reduce((acc, group) => acc + group.length, 0);

      if (totalKeywords > KEYWORD_LIMIT) {
        setShowWarning(true);
        setRestKeywords(newAddedKeywords);
        return;
      }

      if (!!newAddedKeywords.length && !disabled) {
        createKeywords(singleKeywords, keywordGroups);

        if (document.activeElement?.ariaLabel === ADD_KEYWORDS_BUTTON_ARIA_LABEL) {
          if (singleKeywords.length) {
            trackMPEditAddKeywords(data.eventNamePrefix, data.filterLogic, 'single', data.mp);
          }
          if (keywordGroups.length) {
            trackMPEditAddKeywords(data.eventNamePrefix, data.filterLogic, 'group', data.mp);
          }
        }
      }

      form.setFieldsValue({
        keyword: ''
      });
    },
    [
      createKeywords,
      data.eventNamePrefix,
      data.filterLogic,
      data.mp,
      disabled,
      form,
      getKeywords,
      keywords,
      newKeywords
    ]
  );

  const existingSingleKeywords = useMemo(
    () =>
      [...(keywords ?? []), ...(newKeywords ?? [])]
        .filter(keyword => !keyword.groupId && keyword.values.length === 1)
        .map(keyword => keyword.values[0].value.toLowerCase()),
    [keywords, newKeywords]
  );

  const handleSubmit = useCallback(
    (values: { keyword: string }): void => {
      const { keyword } = values;
      onCreateKeywords(keyword);
    },
    [onCreateKeywords]
  );

  const onPressEnter = useCallback(
    (input: string) => {
      if (!input) return;

      const { singleKeywords, keywordGroups } = getKeywords(input);
      if (singleKeywords.length) {
        trackMPEditKeywordShortcut(data.eventNamePrefix, data.filterLogic, 'single', data.mp);
      }
      if (keywordGroups.length) {
        trackMPEditKeywordShortcut(data.eventNamePrefix, data.filterLogic, 'group', data.mp);
      }
    },
    [data.eventNamePrefix, data.filterLogic, data.mp, getKeywords]
  );

  return (
    <>
      <Form className={styles.searchFieldForm} onFinish={handleSubmit} form={form}>
        <div className={styles.container}>
          <div className={classNames(styles.searchField, { [styles.isDisabled]: disabled })}>
            <FontAwesomeIcon
              icon={faSearch}
              className={classNames(styles.searchIcon, { [styles.isDisabled]: disabled })}
            />
            <ul className={styles.keywordsList}>
              {withPreview && onShowMore && (!!keywords?.length || !!newKeywords?.length) && (
                <KeywordsPreview
                  keywords={keywords ?? []}
                  newKeywords={newKeywords}
                  disabled={disabled}
                  onShowMore={onShowMore}
                />
              )}
              <KeywordsInput
                disabled={disabled}
                label={placeholder}
                form={form}
                existingSingleKeywords={existingSingleKeywords || []}
                onPressEnter={onPressEnter}
              />
            </ul>
          </div>
          {extendedVersion && (
            <InfoIcon desc={t('Tenders.Keywords.searchWithAndLogicDesc')} className={styles.infoIcon} />
          )}
        </div>
        {extendedVersion && !disabled && (
          <Button htmlType={'submit'} type={'primary'} disabled={disabled} aria-label={ADD_KEYWORDS_BUTTON_ARIA_LABEL}>
            {addButtonLabel || t('Common.addKeywords')}
          </Button>
        )}
      </Form>
      {showWarning && !!restKeywords.length && (
        <KeywordsLimitWarnModal onClose={onHideWarning} keywords={restKeywords} />
      )}
    </>
  );
};

export default KeywordsArea;

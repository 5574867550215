import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/common/Button';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import styles from './index.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCopyToClipboard } from 'react-use';
import classNames from 'classnames';

interface IProps {
  value: string;
  className?: string;
}
export const CopyBox: FC<IProps> = props => {
  const { value, className } = props;
  const { t } = useTranslation();

  const [, copyToClipboard] = useCopyToClipboard();
  const onCopy = useCallback(() => {
    copyToClipboard(value);
  }, [copyToClipboard, value]);

  return (
    <div className={className ? classNames(styles.copyInfo, className) : styles.copyInfo}>
      <span className={styles.copyContent}>
        <span>{value}</span>
      </span>
      <Button icon={<FontAwesomeIcon icon={faCopy} />} onClick={onCopy} className={styles.copyButton} type={'primary'}>
        {t('Common.copy')}
      </Button>
    </div>
  );
};

export default CopyBox;

import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './index.module.scss';
import KeywordsArea from './KeywordsArea';
import { Button } from 'src/common';
import { useTranslation } from 'react-i18next';
import { BlockTitle } from 'src/shared';
import AdvancedSettingsModal from './AdvancedSettingsModal';
import { useKeywords } from 'src/models/keywords/hooks';
import classNames from 'classnames';

interface IProps {
  disabled: boolean;
}

export const Keywords: FC<IProps> = props => {
  const { disabled } = props;
  const { t } = useTranslation();
  const { data } = useKeywords();
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className={classNames(styles.preferencesKeywords, { [styles.isTitleHidden]: !data.texts.sectionTitle })}>
      <BlockTitle
        title={data.texts.sectionTitle}
        description={data.texts.sectionDesc}
        icon={data.texts.sectionIcon && { defaultIcon: data.texts.sectionIcon }}
        className={styles.title}
      ></BlockTitle>
      <div className={styles.keywords}>
        <KeywordsArea
          disabled={disabled}
          keywords={data.keywords}
          newKeywords={data.newKeywords}
          placeholder={data.texts.searchFieldPlaceholder}
          withPreview
          onShowMore={() => setModalVisible(true)}
        />
        <div className={styles.footer}>
          <Button
            onClick={() => setModalVisible(true)}
            type={'primary'}
            icon={<FontAwesomeIcon icon={data.texts.searchFieldButtonIcon} />}
          >
            {t('Tenders.Keywords.seeAllSettings')}
          </Button>
          {modalVisible && <AdvancedSettingsModal onClose={() => setModalVisible(false)} disabled={disabled} />}
        </div>
      </div>
    </div>
  );
};

export default Keywords;

import React, { FC, useCallback, useMemo, useState } from 'react';
import { EmptyFilter, InfoIcon, SearchFieldTag, Table, Tooltip } from 'src/common';
import { useTranslation, Trans } from 'react-i18next';
import { ColumnProps } from 'antd/lib/table';
import styles from './index.module.scss';
import {
  DEFAULT_KEYWORD_SETTING,
  SearchType,
  SELECTABLE_LOGIC,
  HighlightColorDict,
  HighlightColor,
  MpKeywordAndGroup
} from 'src/models/matchingProfiles/types';
import DeleteButton from './DeleteButton';
import SelectField from './SelectField';
import { checkIfOptionIsEnabled } from '../helpers';
import InfoLabel from 'src/shared/InfoLabel';
import { useFeatureFlag, FeatureFlag } from 'src/helpers/featureFlag';
import { FilterLogic, SearchLogic } from '@tendium/prom-types';
import classNames from 'classnames';
import { faInbox } from '@fortawesome/pro-light-svg-icons';
import { Key, TableRowSelection } from 'antd/lib/table/interface';
import { useKeywords } from 'src/models/keywords/hooks';
import { isStrings } from 'src/helpers';

interface IProps {
  deleteDescText: string;
  disabled?: boolean;
}

export const KeywordsTable: FC<IProps> = props => {
  const { disabled, deleteDescText } = props;
  const { t } = useTranslation();
  const MPBroadOrNarrowSearchFlag = useFeatureFlag(FeatureFlag.MatchingProfile_BroadOrNarrowSearchInDocuments);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const { data } = useKeywords();

  const dataSourceMap = useMemo(
    () =>
      new Map(
        [...(data.keywords ?? []), ...data.newKeywords].map(item =>
          item.groupId
            ? [`group_${item.groupId ? item.groupId : JSON.stringify(item)}`, item]
            : [item.values[0].id, item]
        )
      ),
    [data.keywords, data.newKeywords]
  );

  const dataSource = useMemo(
    () => Array.from(dataSourceMap.entries(), ([rowId, item]) => ({ ...item, rowId })),
    [dataSourceMap]
  );

  const selectedKeywords = useMemo(
    () => selectedRows.map(rowId => dataSourceMap.get(rowId)).filter(Boolean),
    [selectedRows, dataSourceMap]
  );

  const columns = useMemo((): ColumnProps<MpKeywordAndGroup>[] => {
    return [
      {
        title: !!selectedRows.length ? (
          <SelectField
            values={Object.values(HighlightColor).map(color => ({
              value: color,
              label: <span className={classNames(styles.color, styles[color])}></span>
            }))}
            label={t('Common.color')}
            field={'highlightColor'}
            keywords={selectedKeywords as MpKeywordAndGroup[]}
            disabled={disabled}
            className={styles.colorDropdown}
            mode={'bulk'}
          />
        ) : (
          t('Tenders.keywords')
        ),
        key: 'values',
        dataIndex: 'values',
        className: styles.colKeyword,
        render: (values: { id: string; value: string }[], record) => (
          <>
            {values.some(keyword => keyword.value.includes('-')) && (
              <InfoIcon
                desc={
                  <Trans
                    i18nKey={t('Tenders.Keywords.weDoNotConsiderDashDesc', {
                      wordWithoutDash: values.find(keyword => keyword.value.includes('-'))?.value.replace('-', ' '),
                      wordWithDash: values.find(keyword => keyword.value.includes('-'))?.value
                    })}
                    components={{
                      1: <span className={styles.greyedOut}></span>
                    }}
                  />
                }
                className={styles.infoIcon}
              />
            )}
            {values.map((keyword, idx) => (
              <div
                key={keyword.id}
                className={classNames(styles.colorContainer, { [styles.isShort]: keyword.value.length < 6 })}
              >
                <SelectField
                  values={Object.values(HighlightColor).map(color => ({
                    value: color,
                    label: <span className={classNames(styles.color, styles[color])}></span>
                  }))}
                  label={
                    <Tooltip title={keyword.value}>
                      <SearchFieldTag
                        value={keyword.value}
                        color={HighlightColorDict[record.highlightColor]}
                        className={styles.keyword}
                      />
                    </Tooltip>
                  }
                  dropdownIcon={false}
                  field={'highlightColor'}
                  keywords={[record]}
                  disabled={disabled || !!selectedRows.length}
                  className={styles.colorDropdown}
                  mode={'single'}
                />
                {idx !== values.length - 1 && (
                  <span className={styles.keywordsPlusLabel}>&nbsp;{t('Common.and')}&nbsp;</span>
                )}
              </div>
            ))}
          </>
        )
      },
      {
        title: !!selectedRows.length ? (
          <SelectField
            values={Object.values(SearchType).map(type => ({
              value: type,
              label: t(`Tenders.SearchType.${type}`),
              disabled:
                data.filterLogic === FilterLogic.Filter &&
                !(data.keywordWidth && checkIfOptionIsEnabled(data.keywordWidth, type, MPBroadOrNarrowSearchFlag))
            }))}
            label={t('Tenders.whereToSearch')}
            field={'searchType'}
            keywords={selectedKeywords as MpKeywordAndGroup[]}
            disabled={disabled}
            mode={'bulk'}
          />
        ) : (
          <InfoLabel title={t('Tenders.whereToSearch')} desc={<Trans i18nKey={'Tenders.whereToSearchDesc'} />} />
        ),
        key: 'searchType',
        dataIndex: 'searchType',
        className: styles.colSearchTypeSetting,
        render: (searchType: SearchType, record) => (
          <SelectField
            values={Object.values(SearchType).map(type => ({
              value: type,
              label: t(`Tenders.SearchType.${type}`),
              disabled:
                data.filterLogic === FilterLogic.Filter &&
                !(data.keywordWidth && checkIfOptionIsEnabled(data.keywordWidth, type, MPBroadOrNarrowSearchFlag))
            }))}
            selectedValue={searchType || DEFAULT_KEYWORD_SETTING.searchType}
            label={t('Tenders.whereToSearch')}
            field={'searchType'}
            keywords={[record]}
            disabled={disabled || !!selectedRows.length}
            hideLabel
            mode={'single'}
          />
        )
      },
      {
        title: !!selectedRows.length ? (
          <SelectField
            values={SELECTABLE_LOGIC.map(el => ({
              value: el,
              label: t(`Tenders.SearchLogic.${el}`)
            }))}
            label={t('Tenders.searchLogic')}
            field={'searchLogic'}
            keywords={selectedKeywords as MpKeywordAndGroup[]}
            disabled={disabled}
            mode={'bulk'}
          />
        ) : (
          <InfoLabel title={t('Tenders.searchLogic')} desc={<Trans i18nKey={'Tenders.searchLogicDesc'} />} />
        ),
        key: 'searchLogic',
        dataIndex: 'searchLogic',
        className: styles.colSetting,
        render: (searchLogic: SearchLogic, record) => (
          <SelectField
            values={SELECTABLE_LOGIC.map(el => ({
              value: el,
              label: t(`Tenders.SearchLogic.${el}`)
            }))}
            selectedValue={searchLogic || DEFAULT_KEYWORD_SETTING.searchLogic}
            label={t('Tenders.searchLogic')}
            field={'searchLogic'}
            keywords={[record]}
            disabled={disabled || !!selectedRows.length}
            hideLabel
            mode={'single'}
          />
        )
      },
      {
        title: (
          <DeleteButton
            keywords={(selectedKeywords as MpKeywordAndGroup[]).map(keyword => ({
              values: keyword.values,
              groupId: keyword.groupId ?? null
            }))}
            deleteDescText={deleteDescText}
            mode={'bulk'}
            onBulkDelete={() => setSelectedRows([])}
          />
        ),
        key: 'values',
        dataIndex: 'values',
        render: (values: { id: string; value: string }[], record) =>
          !disabled && (
            <DeleteButton
              keywords={[{ values, groupId: record.groupId ?? null }]}
              deleteDescText={deleteDescText}
              mode={'single'}
            />
          )
      }
    ];
  }, [
    selectedRows.length,
    t,
    selectedKeywords,
    disabled,
    deleteDescText,
    data.filterLogic,
    data.keywordWidth,
    MPBroadOrNarrowSearchFlag
  ]);

  const onSelectChange = useCallback((selectedKeys: Key[]) => {
    if (selectedKeys.length > 0 && isStrings(selectedKeys)) {
      setSelectedRows(selectedKeys);
      return;
    }
    setSelectedRows([]);
  }, []);

  const rowSelectionConfig: TableRowSelection<MpKeywordAndGroup> = {
    onChange: onSelectChange,
    preserveSelectedRowKeys: false,
    selectedRowKeys: selectedRows
  };

  return (
    <Table
      className={classNames(styles.table, { [styles.isBulkMode]: !!selectedRows.length })}
      columns={columns}
      dataSource={dataSource}
      rowKey={'rowId'}
      rowSelection={rowSelectionConfig}
      locale={{
        emptyText: (
          <EmptyFilter
            title={t('Tenders.Keywords.noDataTitle')}
            desc={data.texts.noDataDesc}
            icon={faInbox}
            className={styles.emptyTable}
          />
        )
      }}
    />
  );
};

export default KeywordsTable;
